import { useCart } from 'components/Cart'
import { navigate } from 'gatsby'
import { useEffect } from 'react'
import { ArrayParam, NumberParam, useQueryParam } from 'use-query-params'

const OrderPage = () => {
  const [skus] = useQueryParam('sku', ArrayParam)
  const [quantity] = useQueryParam('quantity', NumberParam)
  const cart = useCart()

  useEffect(() => {
    cart.addItemToCart(skus[0], quantity)
    navigate('/basket', { replace: true })
  }, [])

  return null
}

export default OrderPage
